.FacebookLogin__button-facebook___1vc-L {
  background: #1778F2;
  border: none;
}
.FacebookLogin__button-facebook___1vc-L > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.FacebookLogin__button-facebook___1vc-L:hover {
  background: #1778F2;
  filter: none;
  transform: none;
  border: none;
}
.FacebookLogin__button-facebook___1vc-L:active {
  background: #1778F2;
  filter: none;
  transform: none;
  border: none;
}
